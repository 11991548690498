.style-list {
    margin-bottom: rem(-4px);
    margin-right: rem(-4px);
    display: flex;
    flex-wrap: wrap;

    & .style-list-item {
        margin-bottom: rem(4px);
        margin-right: rem(4px);

        & .style-list-link {
            width: rem(28px);
            height: rem(28px);
            display: block;
            text-decoration: none;
            position: relative;
            border-radius: 30px;
            border-color: #aaa;
            border: 0.5px solid;
            transition: all 0.2s linear;

            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
        &.active {
            & .style-list-link {
                &:before {
                    content: "\f00c";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    font-size: rem(14px);
                    line-height: 1;
                    color: $dark;
                    opacity: 0.4;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include fontawesome();
                }
                &.bg-dark {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }
}
