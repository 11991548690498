.report {
    background: var(--app-component-bg);

    & > div {
        &:not(.report-footer) {
            margin-bottom: rem(20px);
        }
    }
    & .report-company {
        font-size: rem(20px);
        font-weight: $font-weight-bold;
    }
    & .report-header {
        margin: 0;
        background: var(--bs-light);

        @include media-breakpoint-down(md) {
            display: block;

            & > div {
                & + div {
                    border-top: 1px solid mix($black, $light, 5%);
                }
            }
        }
    }
    & .report-header-data {
        & strong {
            font-size: rem(12px);
            font-weight: $font-weight-bold;
        }
    }
    & .report-group-header {
        background: var(--bs-light);

        & strong {
            font-size: rem(14px);
            font-weight: $font-weight-bold;
        }
        & small {
            font-size: rem(12px);
        }
    }
    & .report-group-header-data {
        & strong {
            font-size: rem(12px);
            font-weight: $font-weight-bold;
        }
        & small {
            font-size: rem(12px);
            font-weight: normal;
        }
    }
    & .report-note {
        color: rgba(var(--app-component-color-rgb), 0.5);
        font-size: rem(14px);
        line-height: 1.75;
    }
    & .report-divider {
        height: 0;
        margin:  $spacer * .5;
        overflow: hidden;
        border-top: 1px solid rgba(0, 0, 0, 0.175);
        opacity: 1;
    }
}
/*
    & .report-to {
        padding: rem(20px);
        flex: 1;

        & strong {
            font-size: rem(16px);
            font-weight: $font-weight-bold;
        }
    }
    & .report-date {
        text-align: right;
        padding: rem(20px);
        flex: 1;

        @include media-breakpoint-down(md) {
            text-align: left;
        }

        & .date {
            font-size: rem(16px);
            font-weight: $font-weight-bold;
        }
    }
    & .report-price {
        background: var(--bs-light);
        width: 100%;
        display: flex;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        & small {
            font-size: rem(12px);
            font-weight: normal;
            display: block;
        }
        & .report-price-right {
            margin-left: auto;
            padding: rem(20px);
            font-size: rem(28px);
            font-weight: 300;
            position: relative;
            vertical-align: bottom;
            text-align: right;
            color: var(--bs-light);
            background: var(--bs-dark);
            min-width: 25%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & small {
                display: block;
                opacity: 0.6;
                position: absolute;
                top: rem(15px);
                left: rem(20px);
                font-size: rem(12px);
            }
        }
        & .report-price-left {
            padding: rem(20px);
            font-size: rem(20px);
            font-weight: $font-weight-bold;
            position: relative;
            vertical-align: middle;
            flex: 1;

            & .report-price-row {
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    display: block;
                    text-align: center;
                }

                & .sub-price {
                    padding: 0 rem(20px);

                    @include media-breakpoint-down(md) {
                        padding: 0;

                        & + .sub-price {
                            padding-left: rem(20px);
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        & + .sub-price {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    & .report-footer {
        border-top: 1px solid var(--app-component-border-color);
        padding-top: rem(15px);
        font-size: rem(11px);
        color: rgba(var(--app-component-color-rgb), 0.5);
    }

    & .table-report {
        font-size: rem(13px);

        @include media-breakpoint-down(md) {
            white-space: nowrap;
        }
    }
*/
