.report {
    //padding-top: $pos-booking-header-height;

    @include media-breakpoint-up(lg) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & .report-content {
        height: calc(100% - 52px);
        flex: 1;
        display: flex;

        & .pos-booking-content {
            flex: 1;

            & .pos-booking-content-container {
                //padding: $spacer * 1.75;
                //padding: $spacer;
                height: 100%;
            }
            & .table-booking {
                display: block;
                margin-bottom: $spacer * 1.25;
                text-decoration: none;

                & .table-booking-container {
                    background: $gray-300;
                    color: $black;
                    // border-radius: $border-radius-lg;
                    transition: box-shadow 0.1s linear;

                    // &:hover {
                    //     box-shadow: 0 0 0 5px rgba($gray-300, 0.5);
                    // }
                    & .table-booking-header {
                        padding: $spacer;

                        & .title {
                            font-weight: $font-weight-bold;
                            color: $gray-800;
                        }
                        & .no {
                            font-size: $font-size-lg * 2.5;
                            font-weight: $font-weight-bold;
                        }
                        & .desc {
                            font-weight: $font-weight-bold;
                            color: $gray-800;
                        }
                    }
                    & .table-booking-body {
                        background: $gray-100;
                        border-bottom-left-radius: $border-radius-lg;
                        border-bottom-right-radius: $border-radius-lg;

                        & .booking {
                            font-size: $font-size-sm;
                            padding: $spacer * 0.333 $spacer;
                            display: flex;

                            & .time {
                                width: rem(60px);
                                font-weight: $font-weight-bold;
                                color: $gray-300;
                            }
                            & .info {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                flex: 1;
                            }
                            & .status {
                                font-size: rem(6px);
                                color: $gray-800;
                                display: flex;
                                align-items: center;

                                @if $enable-rtl {
                                    padding-right: $spacer;
                                } @else {
                                    padding-left: $spacer;
                                }

                                &.upcoming {
                                    color: $warning;
                                }
                                &.in-progress {
                                    color: $success;
                                }
                            }
                            &.highlight {
                                background: $gray-300;
                            }
                            & + .booking {
                                border-top: 1px solid $gray-300;
                            }
                        }
                    }
                }
            }
            & .date {
                border: 2px solid $gray-100;
                max-width: rem(240px);
                border-radius: $border-radius-lg;

                & .form-control {
                    border: none;
                    background: none;
                    font-size: $font-size-lg;
                    font-weight: $font-weight-bold;
                    color: var(--app-component-color);
                    box-shadow: none;
                    border-radius: 0;

                    &::placeholder {
                        color: $gray-800;
                    }
                }
                & .input-group-addon {
                    background: none;
                }
            }
        }
    }
}
